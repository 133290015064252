import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
// import Pagination from "../Pagination/Pagination";
import "./Blog.css";
import parse from "html-react-parser";
import { IoIosSearch } from "react-icons/io";
import { FaCalendar } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Nodata from "../../assets/img/no-data.jpg";

const Blog = () => {
  const [Loading, setLoading] = useState(true);
  const [PopularDataLoading, setPopularDataLoading] = useState(false);
  const [categories, setCategories] = useState();
  const [popularBlogData, setPopularBlogData] = useState();
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const itemsPerPage = 10;
  const [searchBlog, setSearchBlog] = useState("");
  const [lodeMore, setLodeMore] = useState("Load More");

  useEffect(() => {
    const apiData = {
      companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
    };

    setPopularDataLoading(true);
    const getPopularAPI = async (apiData) => {
      // const data = await axios.get(
      //   `http://192.168.1.35:8080/api/v1/blog/getAllPopularBlog?search=&page_no=1&rows_per_page=10&company_id=${apiData.companyid}`
      // );
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/getAllPopularBlog?search=&page_no=1&rows_per_page=10&company_id=${apiData.companyid}`
      );

      return data;
    };

    getPopularAPI(apiData)
      .then((res) => {
        setCategories(res.data.responseObj.category);
        setPopularBlogData(res.data.responseObj.result);
        setPopularDataLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setPopularDataLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage,
      companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
      searchBlog: searchBlog,
    };

    const GetBlogApi = async (apiData) => {
      setLoading(true);
      const { data } = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_all_blog?search=&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}&company_id=${apiData.companyid}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setBlogData(res.responseObj.result);
        // console.log(res.responseObj.result);
        setCount(res.responseObj.count || res.responseObj.result.length);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setLoading(false);
      });
  }, []);

  const totalPages = Math.ceil(count / itemsPerPage);

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogdetails/${data}`);
  };
  const handleCategoryClick = (value) => {
    navigate(`/blog/${value}`);
  };
  const handleSearch = (value) => {
    navigate(`/blog/search/${value}`);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const LoadMoreFunction = () => {
    setLodeMore("Loading...");

    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage + 1,
      companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
      searchBlog: searchBlog,
    };

    const GetMoreBlogApi = async (apiData) => {
      const { data } = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_all_blog?search=&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}&company_id=${apiData.companyid}`
      );

      return data;
    };

    if (blogData.length == count) {
      return;
    }

    GetMoreBlogApi(apiData)
      .then((res) => {
        setBlogData((prev) => [...prev, ...res.responseObj.result]);
        // setCount(res.responseObj.count);
        setCurrentPage((prev) => prev + 1);
        setLodeMore("Load More");
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        setLodeMore("Load More");
        toast.error("Something went wrong ");
      });
  };

  return (
    <div className="blog-a">
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{
            backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`,
          }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Blog</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Blog</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="team" className="team pt-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          {Loading ? (
            <div className="mt-5" style={{ textAlign: "center" }}>
              <h4>Loading...</h4>
            </div>
          ) : (
            <>
              {count ? (
                <div
                  className="row aos-init aos-animate mt-3"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="blogHeading ">
                    <div>
                      <h1>Blog</h1>
                    </div>
                    <form
                      className="blogSearchForm"
                      onSubmit={() => {
                        handleSearch(searchBlog);
                      }}
                    >
                      <div className="input-group">
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                          value={searchBlog}
                          onChange={(e) => setSearchBlog(e.target.value)}
                        />
                        <div className="input-group-append bg-light">
                          <button
                            id="button-addon1"
                            type="submit"
                            className="btn btn-link text-primary "
                            // onClick={()=>{handleSearch(searchBlog)}}
                          >
                            <IoIosSearch />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  {blogData?.map((item, index) =>
                    index === 0 ? (
                      <>
                        <div
                          key={index}
                          className="col-lg-8 col-md-12 d-flex blogIndex0"
                        >
                          <div className="member">
                            <img
                              src={`${item?.blog_image}`}
                              className="img-fluid blogImg-a"
                              alt="Blog post"
                            />
                            <div className="member-content">
                              <h2 className="blog-towbox th-a">
                                <div className="d-flex gap-2">
                                  {item?.category
                                    ?.split(",")
                                    .map((categoryItem) => {
                                      return (
                                        <div
                                          className="blogCardCategory"
                                          key={categoryItem}
                                          onClick={() => {
                                            handleCategoryClick(categoryItem);
                                            handleLinkClick();
                                          }}
                                        >
                                          {categoryItem}
                                        </div>
                                      );
                                    })}
                                </div>
                                <div className="d-flex">
                                  <FaCalendar />
                                  &nbsp;
                                  {formatDate(item.created_on)}
                                </div>
                              </h2>
                              <h4 className="blogTitle-a">{item.blog_title}</h4>
                              {/* <span>Stay Ahead of the Curve</span> */}
                              <p className="blogContent-aa">
                                {parse(item.content)}
                              </p>
                              <div
                                className="read-more-btn readMore"
                                onClick={() => {
                                  handleCardClick(item.blog_id);
                                  handleLinkClick();
                                }}
                              >
                                Read More
                                <i className="fa-solid fa-arrow-right-long"></i>
                              </div>
                              {/* Uncomment and use social links if needed */}
                              {/* <div className="social">
                      <a href="#"><i className="bi bi-twitter"></i></a>
                      <a href="#"><i className="bi bi-facebook"></i></a>
                      <a href="#"><i className="bi bi-instagram"></i></a>
                      <a href="#"><i className="bi bi-linkedin"></i></a>
                    </div> */}
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-12 d-flex">hello</div> */}
                        <div className="col-lg-4 col-md-12 blog-right-main">
                          <div className="blog-right">
                            <div className="col-lg-12">
                              <div>
                                <h6>Category</h6>
                              </div>
                              <div className="blogCategories">
                                {PopularDataLoading ? (
                                  <div style={{ textAlign: "center" }}>
                                    <h6>Loading...</h6>
                                  </div>
                                ) : (
                                  <>
                                    {categories?.length ? (
                                      categories.map((item, index) => {
                                        return (
                                          <span
                                            key={index} // Added key for each element in the list
                                            className="blogCategory"
                                            onClick={() => {
                                              handleCategoryClick(item);
                                              handleLinkClick();
                                            }}
                                          >
                                            {item}
                                          </span>
                                        );
                                      })
                                    ) : (
                                      <div className="emptyCategory">No Category Present</div>
                                    )}
                                  </>
                                )}
                              </div>
                              <hr />
                              <div>
                                <h6 className="m-0">Popular articles</h6>
                              </div>

                              {PopularDataLoading ? (
                                <div style={{ textAlign: "center" }}>
                                  <h6>Loading...</h6>
                                </div>
                              ) : (
                                <>
                                  <div className="PopularBlogWrap">
                                    {popularBlogData.length ? (
                                      <>
                                        {popularBlogData.map((item, index) => {
                                          return (
                                            <div
                                              className="card bg-light-subtle mt-2 PopularBlogCard"
                                              onClick={() => {
                                                handleCardClick(item.blog_id);
                                                handleLinkClick();
                                              }}
                                            >
                                              <img
                                                src={item.blog_image}
                                                className="card-img-top"
                                                alt="Blog Image"
                                              />
                                              <div className="card-body">
                                                <div className="text-section">
                                                  <p
                                                    title={item.blog_title}
                                                    className="card-text"
                                                  >
                                                    {item.blog_title}
                                                  </p>
                                                  <span id="categorySpan">
                                                    {item.category
                                                      .split(",")[0]
                                                      .trim()}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <div className="EmptyPopularData">
                                        No Popular Article Present
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              <hr />

                              <div>
                                <h6> Follow us</h6>
                              </div>

                              <div>
                                <div className=" blogSocialLinks">
                                  <a
                                    href="https://www.facebook.com/suugam.spotrack" target="_blank"
                                    className="facebook"
                                  >
                                    <FaFacebook />
                                  </a>
                                  <a
                                    href="https://www.youtube.com/@Suugam.Spotrack" target="_blank"
                                    className="youtube"
                                  >
                                    <FaYoutube />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/spotrack.in_/" target="_blank"
                                    className="instagram"
                                  >
                                    <FaInstagram />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/suugamspotrack/" target="_blank"
                                    className="linkedin"
                                  >
                                    <FaLinkedin />
                                  </a>
                                  <a
                                    href="https://x.com/i/flow/login?redirect_after_login=%2FSuugam_Spotrack" target="_blank"
                                    className="twitter"
                                  >
                                    <FaXTwitter />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div key={index} className="col-lg-4 col-md-6 d-flex">
                        <div className="member">
                          <img
                            src={`${item.blog_image}`}
                            className="img-fluid blogImg-a"
                            alt="Blog post"
                          />
                          <div className="member-content">
                            <h2 className="blog-towbox">
                              {/* <span>
                            <i className="fa-solid fa-user"></i>By{" "}
                            {item.blog_author}
                          </span> */}
                              <span className="d-flex">
                                <FaCalendar />
                                &nbsp;
                                {formatDate(item.created_on)}
                              </span>
                            </h2>
                            <h4 className="blogTitle-a">{item.blog_title}</h4>
                            <span>{item.category.split(",")[0].trim()}</span>
                            <p className="blogContent-a">
                              {parse(item.content)}
                            </p>
                            <div
                              className="read-more-btn readMore"
                              onClick={() => {
                                handleCardClick(item.blog_id);
                                handleLinkClick();
                              }}
                            >
                              Read More
                              <i className="fa-solid fa-arrow-right-long"></i>
                            </div>
                            {/* Uncomment and use social links if needed */}
                            {/* <div className="social">
                      <a href="#"><i className="bi bi-twitter"></i></a>
                      <a href="#"><i className="bi bi-facebook"></i></a>
                      <a href="#"><i className="bi bi-instagram"></i></a>
                      <a href="#"><i className="bi bi-linkedin"></i></a>
                    </div> */}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="mt-5 d-flex w-100 flex-column justify-content-center align-items-center">
                  <div>
                    <img src={Nodata} />
                  </div>
                  <h6>
                  No Blog Present
                  </h6>
                </div>
              )}

              
              { count === blogData.length ? "": (
                <button
                  disabled={count === blogData.length}
                  style={{
                    cursor:
                      count === blogData.length ? "not-allowed" : "pointer",
                  }}
                  className="LoadMoreBlog w-100"
                  onClick={LoadMoreFunction}
                >
                  {lodeMore}
                </button>
              )}
            </>
          )}
        </div>
      </section>
      {/* End Blog Section */}

      {/* <div className="pagination-a" onClick={handleLinkClick}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          handleLinkClick={handleLinkClick}
        />
      </div> */}
    </div>
  );
};

export default Blog;
