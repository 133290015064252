import React from "react";
import { Link } from 'react-router-dom';
import ourStory from '../../assets/img/our-story.jpg'
import whoWeAre1 from '../../assets/img/who-we-are1.jpg'
import ourMission from '../../assets/img/our-mission.jpg'
import ourVision from '../../assets/img/our-vision.png'
import expertiseIcon from '../../assets/img/expertise-icon.png'
import ourServiseIcon from '../../assets/img/our-servise-icon.png'
import aankitsir from '../../assets/img/aankitsir.png'
import Ankitamaam from '../../assets/img/Ankitamaam.png'

const AboutUs = () => {
  return (
    <div>
      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center" style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>About us </h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
            <li>
                <Link to="/">Home</Link>
              </li>
              <li>About</li>
            </ol>
          </div>
        </nav>
      </div>

      {/* About Us Section */}
      <section id="about" className="about pt-4">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Welcome to Spotrack</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 position-relative align-self-start order-lg-last order-first">
              <img src={ourStory}  className="img-fluid our-vision" alt="Our Story" />
            </div>
            <div className="col-lg-7 content order-last our-story-text order-lg-first">
              <h3>Our Story</h3>
              <p className="fst-italic">
                At Spotrack, we are passionate about the limitless possibilities that the Internet of Things (IoT) brings to the world. Our journey began with a shared vision: to harness the power of connectivity and innovation to create a smarter, more efficient future.
              </p>
            </div>
          </div>
          <div className="row mt-4 align-items-center about-box">
            <div className="col-lg-5 position-relative align-self-start order-lg-last order-first">
              <img src={whoWeAre1} className="img-fluid our-vision" alt="Who We Are" />
            </div>
            <div className="col-lg-7 content our-story-text order-last order-lg-last">
              <h3>Who We Are</h3>
              <p className="fst-italic">
                We are a SaaS-based firm providing IoT-based customized tracking solutions. We are a highly innovative and growing company in the arena of IoT firms. We specialize in automated systems designed for remote tracking, fuel monitoring, fleet management, and much more.
              </p>
            </div>
          </div>
          <div className="row mt-4 align-items-center about-box">
            <div className="col-lg-5 position-relative align-self-start order-lg-last order-first">
              <img src={ourMission} className="img-fluid our-vision" alt="Our Mission" />
            </div>
            <div className="col-lg-7 content our-story-text order-last order-lg-first">
              <h3>Our Mission</h3>
              <p className="fst-italic">
                Organize the entire construction industry & offer complete software & hardware solutions at a single platform. Emerge as a sole name taken synonymously with big construction industry tycoons. Organize the entire construction industry & offer complete software & hardware solutions at a single platform. Emerge as a sole name taken synonymously with big construction industry tycoons.
              </p>
            </div>
          </div>
          <div className="row mt-4 align-items-center about-box">
            <div className="col-lg-5 position-relative align-self-start order-lg-last order-first">
              <img src={ourVision} className="img-fluid our-vision" alt="Our Vision" />
            </div>
            <div className="col-lg-7 content our-story-text order-last order-lg-last">
              <h3>Our Vision</h3>
              <p className="fst-italic">
                Expand on a global vertical by offering 50,000+ vehicles on hire & 100,000+ digital assets with Suugam by 2027. Focus on strengthening the brand value and a big IPO listing in the next 
              </p>
              <p><strong>Innovation:</strong> We thrive on pushing boundaries and exploring new horizons.</p>
              <p><strong>Collaboration:</strong> We believe in the power of partnerships and working together to achieve greatness.</p>
              <p><strong>Integrity:</strong> Our commitment to transparency, honesty, and ethical business practices is unwavering.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Counter Section */}
      <section id="stats-counter" className="stats-counter pt-0">
        <div className="container" data-aos="fade-up">
          {/* <div className="row gy-4">
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
                <p>Company</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
                <p>Assets</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" className="purecounter"></span>
                <p>Fuel Sensor </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
                <p>GPS</p>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      {/* Our Team Section */}
      <section id="team" className="team pt-0">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>What Sets Us Apart</h2>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6 col-md-6 d-flex">
              <div className="member member-one">
                <div className="member-content member-content-one">
                  <img src={expertiseIcon} alt="Expertise" />
                  <h4>Expertise</h4>
                  <p>
                    With a team of seasoned experts in IoT development, data analytics, and construction, we bring a wealth of knowledge to every project. Comprehensive Solutions From concept to implementation, we provide end-to-end IoT solutions tailored to meet your specific needs. Client-Centric Approach Your success is our priority. We work closely with our clients to understand their goals and deliver customized solutions that exceed expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-flex">
              <div className="member member-one">
                <div className="member-content member-content-one">
                  <img src={ourServiseIcon} alt="Our Services" />
                  <h4>Our Services</h4>
                  <p><strong>Spotrack Development:</strong> Transforming ideas into reality with innovative IoT solutions.</p>
                  <p><strong>Data Analytics:</strong> Extracting meaningful insights from the vast amount of data generated by IoT devices.</p>
                  <p><strong>Construction Asset Management:</strong> Ensuring the highest level of security for your connected ecosystem.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Founder and CEO Section */}
      <section id="about" className="about pt-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          {/* <div className="row gy-4 align-items-center">
            <div className="col-lg-4 position-relative">
              <img src={aankitsir} className="img-fluid aankit-photo" alt="Ankit Sogani" />
            </div>
            <div className="col-lg-8 content order-last aankit-text order-lg-first">
              <h3>Ankkit Kumar Sogani, Founder & CEO</h3>
              <p>
                As we stand at the intersection of technology and navigation, I am thrilled to address each and every member of our dedicated team. Our journey as an IoT company has been nothing short of remarkable, and I am confident that the road ahead holds even greater promise and opportunity.
              </p>
              <p>At Suugam, we understand that in today's dynamic world, precision, reliability, and innovation are the keys to success. Our commitment to providing cutting-edge IoT solutions and sensors has propelled us to the forefront of the industry, and it is your hard work, creativity, and passion that have driven our success. As we look back at our achievements, let us also cast our gaze forward. The future of IoT technology is evolving at an unprecedented pace, and we are poised to lead the way. Whether it's developing more accurate positioning systems, creating user-friendly interfaces, or exploring the integration of artificial intelligence, our commitment to innovation remains unwavering.</p>
            </div>
          </div> */}
          <div className="row gy-4 align-items-center">
            <div className="col-lg-4 position-relative">
              <img src={Ankitamaam} className="img-fluid aankit-photo" alt="Ankita Sogani" />
            </div>
            <div className="col-lg-8 content order-last aankit-text order-lg-end">
              <h3>Ankita Sogani, Co-Founder & CRM Head</h3>
              <p>
                In an industry marked by precision and accuracy, our journey mirrors the meticulous nature of the devices we create. Each milestone reached is a testament to the collaborative efforts of our talented team, whose dedication fuels our success. As we navigate the ever-evolving landscape of GPS and fuel sensor technology, let us continue to embrace challenges as opportunities for growth and improvement. I am particularly proud of the strides we've taken toward fostering diversity and inclusion within our organization. Recognizing that diverse perspectives lead to richer solutions, we are creating an environment where every voice is heard, and every idea is valued. By harnessing the power of diversity, we not only strengthen our team but also fortify our ability to meet the dynamic needs of our industry.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
